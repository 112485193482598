import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class Risk extends Rest {
  /**
   * 资金方统计
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  sideStatistics (params) {
    return http({
      method: 'get',
      url: '/api/product/statistics/capitalSideInfo',
      params
    })
  }

  /**
   * 放款金额占比
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  loanplanStatistics (params) {
    return http({
      method: 'get',
      url: '/api/finance/statistics/loanAmountProportion',
      params
    })
  }

  /**
   * 资产业务分布情况统计
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  areaStatistics (params) {
    return http({
      method: 'get',
      url: '/api/finance/statistics/distributionCondition',
      params
    })
  }

  /**
   * 融资产品情况统计
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  finaaceProductStatistics (params) {
    return http({
      method: 'get',
      url: '/api/finance/statistics/financingProduct',
      params
    })
  }

  /**
   * 融资风险情况统计
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  riskStatistics (params) {
    return http({
      method: 'get',
      url: '/api/finance/statistics/financingRisk',
      params
    })
  }

  /**
   * 供应商融资情况排名
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  gysStatistics (params) {
    return http({
      method: 'get',
      url: '/api/finance/statistics/supplieRranking',
      params
    })
  }

  /**
   * 风控统计
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getRiskManagerAllocationCount (params) {
    return http({
      method: 'get',
      params,
      url: '/api/finance/riskAllocation/getRiskManagerAllocationCount'
    })
  }
}
